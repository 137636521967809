import React from 'react'
import T from 'prop-types'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import Article from 'components/Article'
import CommonMetatags from 'components/metatags/CommonMetatags'
import ArticleMetatags from 'components/metatags/ArticleMetatags'

function PageTemplate({ data }) {
  return (
    <Layout logo={data.logo} reducedHeader>
      <CommonMetatags />
      <ArticleMetatags article={data.page} />
      <Article data={data} />
    </Layout>
  )
}

PageTemplate.propTypes = {
  data: T.shape({
    logo: T.shape({}),
    page: T.shape({
      title: T.string,
    }),
  }),
}

export default PageTemplate

export const pageQuery = graphql`
  query page($id: String!) {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, placeholder: BLURRED)
      }
    }

    followImg: file(relativePath: { eq: "apple-worm.png" }) {
      childImageSharp {
        gatsbyImageData(width: 150, placeholder: BLURRED)
      }
    }

    page: wpPage(id: { eq: $id }) {
      id
      uri
      title
      content
      date
      modified
      featuredImage {
        node {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
